// To be added to all the buttons throughout the console.
.button_design {
  background-color: #952c8b !important;
  border-radius: 10px !important;
  color: white !important;
  margin-bottom: 0.5rem;
  outline: none !important;
  &:hover {
    background-color: $hover !important;
    border-color: $primary !important;
    &:active {
      background-color: $hover !important;
      border-color: $primary !important;
    }
  }
}

.button_design_success {
  background-color: #52c41a !important;
  border-radius: 5px !important;
  color: white !important;
  outline: none !important;
  &:hover {
    background-color: white !important;
    color: #52c41a !important;
    border-color: #52c41a !important;
    &:active {
      background-color: white !important;
      color: #52c41a !important;
      border-color: #52c41a !important;
    }
  }
}

.button_design_failure {
  background-color: #eb2f5b !important;
  border-radius: 5px !important;
  color: white !important;
  // margin-bottom: 1rem;
  outline: none !important;
  &:hover {
    background-color: white !important;
    color: #eb2f5b !important;
    border-color: #eb2f5b !important;
    &:active {
      background-color: white !important;
      color: #eb2f5b !important;
      border-color: #eb2f5b !important;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #952c8b !important;
  border-color: #952c8b !important;
}

.button_design_outline {
  &:hover {
    border-color: #952c8b !important;
    color: #952c8b !important;
  }
}
